
.list-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 40px;
}
.info-step-header {
    padding: 22px 0 0;
}
.big-title {
    font-size: 20px;
    line-height: 1;
    margin: 20px 0;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}
.main-box {
    height: 1%;
    flex: 1;
    background: #fff;
    margin-bottom: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .tab-box {
        font-size: 20px;
        color: #FD4446;
        line-height: 1;
        .item {
            position: relative;
            display: inline-block;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -10px;
                height: 2px;
                background: #FD4446;
            }

        }
    }
    .operate-box {
        margin-top: 30px;
        display: flex;
    }
}
